import React from 'react';
import StaticLayout from 'components/layouts/static-layout';
import {graphql} from 'gatsby';
import PropTypes from 'prop-types';
import {colors} from 'assets/theme';
import map from 'lodash/map';
import styled from 'styled-components';
import {backgroundColor} from 'components/wrappers';

const Swatches = styled.div`
  display: flex;
  flex-wrap: wrap;
`;
const Swatch = styled.div`
  margin: 10px;
`;
const SwatchName = styled.p`
  margin: 10px 0 0;
`;
const SwatchColor = styled.div`
  ${backgroundColor};
  height: 120px;
  width: 120px;
  border-radius: 5px;
`;

const Theme = ({data, location}) => {
  const {title, siteUrl} = data.site.siteMetadata;
  return (
    <StaticLayout
      {...{
        contentPadding: true,
        container: true,
        location,
        seo: {title: `${title} about`, description: `Theme page for ${siteUrl}`},
      }}
    >
      <h1>Theme</h1>
      <h2>Colours</h2>
      <Swatches>
        {map(colors, (val, colorName) => {
          return (
            <Swatch key={colorName}>
              <SwatchColor bgColor={colorName} />
              <SwatchName>{colorName}</SwatchName>
              <SwatchName>{val}</SwatchName>
            </Swatch>
          );
        })}
      </Swatches>
    </StaticLayout>
  );
};

Theme.propTypes = {
  data: PropTypes.shape({
    site: PropTypes.object,
  }),
  location: PropTypes.shape({
    pathname: PropTypes.string,
  }),
};

export default Theme;

export const GATSBY_QUERY = graphql`
  query {
    site {
      siteMetadata {
        title
        siteUrl
      }
    }
  }
`;
